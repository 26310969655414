<template>
	<main class="form padding-b-24">
		<iframe
			class="embedded"
			:src="embed_src"
			scrolling="false"
			frameborder="0"
		></iframe>
	</main>
</template>

<script>
export default {
	name: 'Galleries',

	props: {
		id: {
			required: true,
			type: String,
			default: '',
		},
	},

	computed: {
		embed_src() {
			return `${this.$root.proxy}/galleries`;
		},
	},

	created() {
		this.setMetaSidearm('Galleries');
	},
};
</script>

<style lang="scss">
.embedded {
	width: 100%;
	height: calc(1100px);
}
</style>
